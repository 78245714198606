import * as React from 'react';
import {
  Box,
  Divider,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';

import { API, Auth } from 'aws-amplify';
import { createNewAccount } from '../../../state/account/account';
import { useAppDispatch } from '../../../state/createStore';
import { PrimaryButton } from '../../buttons';
import PricePlans from './price-plan/price-plan';

const CreateAccount = (): JSX.Element => {
  const [plan, setPlan] = React.useState('default');
  const [isLoading, setIsLoading] = React.useState(false);
  const { register, handleSubmit } = useForm();
  const { userId } = useSelector((state: any) => state.user);
  const dispatch = useAppDispatch();

  const setParsedPlan = (e: React.SetStateAction<string>) => {
    switch (e) {
      case 'Enterprise Professional': {
        setPlan('enterpriseProfessional_monthly');
        break;
      }
      case 'Enterprise Intermediate': {
        setPlan('enterpriseIntermediate_monthly');
        break;
      }
      case 'Enterprise Starter': {
        setPlan('enterpriseStarter_monthly');
        break;
      }
      case 'Individual Professional': {
        setPlan('individualProfessional_monthly');
        break;
      }
      default: {
        console.error('ERR');
        break;
      }
    }
  };

  const getStripeLink = async (accountId: string) => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const myInit = {
        body: {
          return_url: window.location.href,
          planName: plan,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await API.post(
        'openscreen',
        `billing/session/${accountId}`,
        myInit
      );
      window.location.href = res.redirectUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async ({ name }: { name: string }) => {
    let newAccountId = '';
    try {
      setIsLoading(true);
      await dispatch(createNewAccount({ companyName: name, userSub: userId }))
        .then(unwrapResult)
        .then((result) => {
          newAccountId = result.accountId;
          toast.success('Account successfully created');
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(false);
      if (plan === 'Individual Starter') {
        navigate('/app');
      } else {
        getStripeLink(newAccountId);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(`Your account could not be created, ${error.message}`);
    }
  };

  return (
    <Box padding="20px" marginX="40px" bgColor="#fff">
      <form action="submit" onSubmit={handleSubmit(onSubmit)}>
        <Flex marginBottom="25px" direction="column">
          <Stack>
            <Heading fontSize="20pt">Create a New Account</Heading>
            <Divider height="15px" orientation="horizontal" />
            <Text>
              Create a new account if you want to separate your billing and
              invoices for new projects
            </Text>
            <Input {...register('name')} placeholder="Company / Account Name" />
          </Stack>
        </Flex>
        <Flex>
          <Heading fontSize="20pt">Choose a Price Plan</Heading>
        </Flex>
        <PricePlans setParsedPlan={setParsedPlan} />
        <Box marginY="15px">
          <PrimaryButton isLoading={isLoading} marginX="none" type="submit">
            Create Account
          </PrimaryButton>
        </Box>
      </form>
      <ToastContainer theme="colored" />
    </Box>
  );
};

export default CreateAccount;
