import * as React from 'react';
import { Box, Input, useRadio } from '@chakra-ui/react';

const RadioCard = (props: any): JSX.Element => {
  const { children, isLoading } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box px="10px" as="label">
      <Input disabled={isLoading} {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="2px"
        _checked={{
          border: '2px',
          borderColor: '#4fc1ff',
        }}
        fontWeight="medium"
      >
        {children}
      </Box>
    </Box>
  );
};

export default RadioCard;
