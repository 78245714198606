/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Wrap, Box, useRadioGroup } from '@chakra-ui/react';

import TierCard from '../tierCard/tierCard';
import RadioCard from '../../../buttons/radioCard/radioCard';
import options from './options';

interface PricePlanProps {
  setParsedPlan: (e: any) => void;
}

const PricePlans = ({ setParsedPlan }: PricePlanProps): JSX.Element => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'plan',
    defaultValue: 'Individual Starter',
    onChange: setParsedPlan,
  });

  const group = getRootProps();

  return (
    <Box marginBottom="30px">
      <Wrap marginTop="10px" justify="center" spacing={0} {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value: value.title });
          return (
            <RadioCard key={value.title} {...radio}>
              <TierCard
                free={value.free}
                title={value.title}
                monthlyPrice={value.monthlyPrice}
                yearlyPrice={value.yearlyPrice}
              >
                <></>
                {value.elements}
              </TierCard>
            </RadioCard>
          );
        })}
      </Wrap>
    </Box>
  );
};

export default PricePlans;
